import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { findIndex } from 'lodash';
import Rollbar from 'rollbar';

import Cluster from './Cluster';

// Top level component that represents a set of clusters being manually
// 'fine-tuned'.
class ClusterList extends Component {

  static propTypes = {
    questionId: PropTypes.string.isRequired,
    processedPath: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      clusters: [],
      loading: true,
      edited: false
    }
  }

  componentDidMount() {
    this.loadClusters();
  }

  loadClusters() {
    this.request = $.ajax({
      type: 'GET',
      url: `/questions/${this.props.questionId}/clusters.json`,
      success: (data) => {
        this.setState({
          clusters: data,
          loading: false,
          edited: false
        });
      },
      failure: () => {
        this.setState({
          loading: false
        });
      }
    });
  }

  submitClusters() {
    let clusters = this.state.clusters.slice(0);
    let submittableClusters = clusters.map((cluster) => {

      // Cluster ideas are all of the supporting ideas, plus the key idea.
      let clusterIdeas =  cluster.supportingIdeas.map((idea) => { return (idea.id) });
      clusterIdeas.push(cluster.keyIdea.id);
      return (
        {
          id: cluster.id,
          key_idea_id: cluster.keyIdea.id,
          ideas: clusterIdeas
        }
      );
    });
    
    // This request redirects, so no need to handle success case.
    this.request = $.ajax({
      type: 'PUT',
      contentType: 'application/json',
      url: `/questions/${this.props.questionId}/clusters/update_all`,
      data: JSON.stringify({clusters: submittableClusters}),
      error: (jqXHR, textStatus, errorThrown) => {
        alert(I18n.t("clusters.index.submit_cluster_alert"));
        Rollbar.error("Error updating clusters", {textStatus, errorThrown});
      }
    });
  }

  markAsProcessed() {
    // This request redirects, so no need to handle success case.
    this.request = $.ajax({
      type: 'PUT',
      url: this.props.processedPath,
      error: (jqXHR, textStatus, errorThrown) => {
        alert(I18n.t("clusters.index.mark_as_processed_alert"));
        Rollbar.error("Error updating clusters", {textStatus, errorThrown});
      }
    });
  }

  pushIdeaToCluster(index, idea) {
    this.setState(update(this.state, {
      clusters: {
        [index]: {
          supportingIdeas: {$push: [idea]}
        }
      }
    }));
  }

  removeIdeaFromCluster(clusterIndex, ideaIndex) {
    this.setState(update(this.state, {
      clusters: {
        [clusterIndex]: {
          supportingIdeas: {$splice: [ [ideaIndex, 1] ]}
        }
      }
    }));
  }

  // Merge two clusters together
  mergeClusters(clusterId, mergeIntoId) {
    // Find the index of the old cluster
    let index = findIndex(this.state.clusters, {id: clusterId});
    let cluster = this.state.clusters[index];

    // Remove it
    let updatedState = update(this.state, {
      clusters: {$splice: [ [index, 1] ] }
    });

    // Find the index of the new cluster
    index = findIndex(updatedState.clusters, {id: mergeIntoId});
    // Add all old ideas, including key idea
    this.setState(update(updatedState, {
      clusters: {
        [index]: {
          supportingIdeas: {
            $push: cluster.supportingIdeas.concat([cluster.keyIdea])
          }
        }
      },
      edited: {$set: true}
    }));
  }

  // Set a new key idea for a cluster
  makeIdeaKey(clusterIndex, ideaIndex, idea) {
    const prevKeyIdea = this.state.clusters[clusterIndex].keyIdea;
    
    this.setState(update(this.state, {
      clusters: {
        [clusterIndex]: {
          // Set new key idea
          keyIdea: {$set: idea},
          supportingIdeas: {
            // Remove the new key idea from the supporting ideas
            $splice: [ [ideaIndex, 1] ],
            // Push old key idea into supporting ideas
            $push: [prevKeyIdea] 
          }
        }
      },
      edited: {$set: true}
    }));
  }

  // Pull a supporting idea out into its own new cluster
  makeNewCluster(clusterIndex, ideaIndex, idea) {
    const newCluster = {
      // Use Date.now() (which returns milliseconds) as a pseudo ID because
      // this is a new record, but we need it to be unique.
      id: Date.now(),
      keyIdea: idea,
      supportingIdeas: []
    };

    this.setState(update(this.state, {
      clusters: {
        // Remove idea from cluster.
        [clusterIndex]: {
          supportingIdeas: {$splice: [ [ ideaIndex, 1 ] ] }
        },
        // Splice it in below the current cluster it belongs to; otherwise
        // it jumps all the way to the bottom which looks a bit janky.
        $splice: [ [ clusterIndex+1, 0, newCluster ] ] 
      },
      edited: {$set: true}
    }));
  }

  render() {
    const { clusters, loading, edited } = this.state;

    if (loading) {
      return (
        <div className='columns is-centered'>
          <div className='column is-narrow'>
            <span className='loader'></span>
          </div>
        </div>
      );
    }

    if (clusters.length > 0) {
      return (
        <DndProvider backend={HTML5Backend}>
          <div className="columns">
            <div className="column"></div>
            <div className="column is-narrow">
              <div className="field is-grouped has-text-centered">
                <button className='button control is-primary is-outlined' disabled={edited ? false : true } onClick={this.loadClusters.bind(this)}>
                  {I18n.t("clusters.index.reset")}
                </button>
                <button className='button control is-primary' disabled={edited ? false : true } onClick={this.submitClusters.bind(this)}>
                  {I18n.t("clusters.index.save_changes")}
                </button>
                <button className="button control is-danger" disabled={edited ? true : false } onClick={this.markAsProcessed.bind(this)}>
                  {I18n.t("clusters.index.mark_as_processed")}
                </button>
              </div>
            </div>
        </div>

          <div className='cluster-list'>
            {clusters.map((cluster, i) => {
              return (
                <div key={i}>
                  <Cluster
                    index={i}
                    cluster={cluster}
                    pushIdea={this.pushIdeaToCluster.bind(this, i)}
                    removeIdea={this.removeIdeaFromCluster.bind(this, i)}
                    makeIdeaKey={this.makeIdeaKey.bind(this, i)}
                    makeNewCluster={this.makeNewCluster.bind(this, i)}
                    mergeClusters={this.mergeClusters.bind(this)}
                  />
                </div>
              );
            })}
          </div>
        </DndProvider>
      );
    } else {
      return <p>{I18n.t("clusters.index.failed_to_load")}</p>
    }
  }
}

export default ClusterList;
