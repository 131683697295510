import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

class Idea extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    idea: PropTypes.object.isRequired,
    listId: PropTypes.number.isRequired,
    removeIdea: PropTypes.func.isRequired,
    makeIdeaKey: PropTypes.func.isRequired,
    makeNewCluster: PropTypes.func.isRequired
  }

  render() {
    const { idea, isDragging, connectDragSource } = this.props;
    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      <div
        className={`level is-mobile idea supporting-idea ${isDragging ? 'dragging' : ''}`}
        style={{ opacity }}
        key={idea.id}>
        <div className='level-left'>
          <i className='fa fa-bars'></i>
          <p>{idea.content}</p>
        </div>
        <div className='level-right'>
          <div className='buttons'>
            <a className='button is-primary' onClick={this.props.makeNewCluster}>
              {I18n.t("clusters.index.new_cluster")}
            </a>
            <a className='button is-primary' onClick={this.props.makeIdeaKey}>
              {I18n.t("clusters.index.make_key")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const source = {
  beginDrag(props) {
    return {
      listId: props.listId,
      idea: props.idea
    }
  },
  endDrag(props, monitor) {
    const idea = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (dropResult && dropResult.listId !== idea.listId) {
      props.removeIdea(idea.index);
    }
  }
}

export default DragSource("IDEA", source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(Idea);
