import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KeyIdea from './KeyIdea';
import Idea from './Idea';

import { DropTarget } from 'react-dnd';

class Cluster extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    cluster: PropTypes.object.isRequired,
    pushIdea: PropTypes.func.isRequired,
    removeIdea: PropTypes.func.isRequired,
    makeIdeaKey: PropTypes.func.isRequired,
    makeNewCluster: PropTypes.func.isRequired,
    mergeClusters: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      collapseIdeas: false
    }
  }

  toggleIdeas() {
    const prevState = this.state.collapseIdeas;
    this.setState({ collapseIdeas: !prevState });
  }

  renderIdeas(cluster) {
    if (this.state.collapseIdeas) { return }
    else {
      return (
        <div className='cluster--ideas'>
          {cluster.supportingIdeas.map((idea, i) => {
            return (
              <Idea
                key={i}
                index={i}
                idea={idea}
                listId={cluster.id}
                removeIdea={() => this.props.removeIdea(i)}
                makeIdeaKey={() => this.props.makeIdeaKey(i, idea)}
                makeNewCluster={() => this.props.makeNewCluster(i, idea)}
              />
            )
          })}
        </div>
      );
    }
  }

  render() {
    const { cluster, connectDropTarget, isOver } = this.props;
    const { containsDraggedIdea, collapseIdeas } = this.state;  

    return connectDropTarget(
      <div className={`cluster ${isOver ? 'is-over' : ''}`}>
        <div className='cluster--key-idea'>
          <KeyIdea 
            listId={cluster.id}
            idea={cluster.keyIdea}
            supportingIdeas={cluster.supportingIdeas.length}
            toggleIdeas={this.toggleIdeas.bind(this)}
            collapseIdeas={collapseIdeas}
          />
        </div>
        {this.renderIdeas(cluster)}
      </div>
		);
  }
}

const target = {
  drop(props, monitor, component ) {
    const sourceObj = monitor.getItem();
    if ( props.cluster.id !== sourceObj.listId ) {
      if (sourceObj.key) {
        component.props.mergeClusters(sourceObj.listId, props.cluster.id);
      } else {
        component.props.pushIdea(sourceObj.idea);
      }
    }
    return {
      listId: props.cluster.id
    };
  }
}

export default DropTarget("IDEA", target, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(Cluster);
