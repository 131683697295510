import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

class KeyIdea extends Component {
  static propTypes = {
    listId: PropTypes.number.isRequired,
    idea: PropTypes.object.isRequired
  }

  renderIdeaCollapseToggle() {
    const { supportingIdeas, toggleIdeas, collapseIdeas } = this.props;

    const supportingIdeasText = I18n.t("clusters.index.supporting_idea", { count: supportingIdeas });

    if (supportingIdeas > 0) {
      return (
        <div className='level-right'>
          { collapseIdeas ? <p className='level-item'>{supportingIdeasText}</p> : '' }
          <p className='level-item'>
            <a className='is-primary' onClick={toggleIdeas}>
              <i className={`fa fa-chevron-${collapseIdeas ? 'down' : 'up'}`}></i>
            </a>
          </p>
        </div>
      );
    } else { return }
  }

  render() {
    const { idea, isDragging, connectDragSource } = this.props;
    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      <div className={`level is-mobile idea ${isDragging ? 'dragging' : ''}`} style={{ opacity }} key={idea.id}>
        <div className='level-left'>
          <i className='fa fa-bars'></i>
          <p>{idea.content}</p>
        </div>
        { this.renderIdeaCollapseToggle() }
      </div>
    );
  }
}

const source = {
  beginDrag(props) {
    return {
      listId: props.listId,
      idea: props.idea,
      key: true
    }
  }
}

export default DragSource("IDEA", source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(KeyIdea);
