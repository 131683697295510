import React from 'react'
import ReactDOM from 'react-dom'

import ClusterList from '../components/ClusterList.js';

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('js-cluster-list');
  if (el) {
    let questionId = el.dataset.questionId;
    let processedPath = el.dataset.processedPath;
    ReactDOM.render(<ClusterList questionId={questionId} processedPath={processedPath} />, el)
  }
})
